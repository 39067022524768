import { GetSingleProductDetailsQuery } from '@generated/graphql';
import { first } from 'lodash';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  isProductConfigurable,
  useProductFullDetail
} from 'src/hooks/useProductFullDetail';
import { useCart } from './useCart';
import { useStore } from './useStore';

export type SingleProduct =
  GetSingleProductDetailsQuery['products']['items'][0] & {
    url_suffix?: string;
    variants?: any;
  };

type ConfigurableOptions = {
  __typename: string;
  attribute_code: string;
  attribute_id: string;
  id: number;
  label: string;
  values: {
    __typename: string;
    default_label: string;
    label: string;
    store_label: string;
    use_default_value: boolean;
    value_index: number;
    swatch_data: null;
  }[];
}[];

interface State<T> {
  current: T;
  set: Dispatch<SetStateAction<T>>;
}

export type ProductFromContext = SingleProduct & Record<string, any>;

interface IProductContext {
  product: ProductFromContext;
  isOutOfStock: boolean;
  isConfigurable: boolean;
  configurableOptions: ConfigurableOptions;
  handleSelectionChange: (optionId: string, selection: number) => void;
  state: {
    quantity: State<number>;
    reviews: State<typeof InitialReviewsState>;
  };
  optionSelections: Map<any, any>;
  isMissingOptions: boolean;
}

interface ProductPageProps {
  children: ReactNode;
  product: any;
}

export const InitialReviewsState = {
  review_count: 0,
  rating_average: 0,
  reviews: {
    items: [],
    page_info: {
      total_pages: 0
    }
  }
};

const ProductContext = createContext({} as IProductContext);

const useProductPage = () => {
  const context = useContext(ProductContext);
  return context;
};

const ProductPageProvider = ({ children, product }: ProductPageProps) => {
  const [quantity, setQuantity] = useState(1);
  const [reviews, setReviews] = useState(InitialReviewsState);
  const { isVendorInTransition } = useStore();
  const { productPageAvailabilityError } = useCart();
  const {
    productDetails,
    mediaGalleryEntries,
    handleSelectionChange,
    optionSelections,
    isMissingOptions
  } = useProductFullDetail({
    product
  });

  const richSnippets = useCallback(() => {
    return {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: productDetails?.variantName,
      image: product?.small_image?.url,
      description: productDetails?.short_description
        ?.replace(/<p>(\s|&nbsp;)*<\/p>/g, '') // Remove empty <p> tags with whitespace
        ?.replace(/<p>(.*?)<\/p>/g, '$1') // Remove <p> tags but keep the content
        ?.replace(/<[^>]+>/g, '') // Remove any remaining HTML tags
        ?.replace(/&nbsp;/g, ' ') // Replace non-breaking spaces
        ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        ?.trim(), // Trim leading/trailing spaces,
      sku: productDetails?.sku,
      mpn: productDetails?.custom_ean,
      brand: {
        '@type': 'Brand',
        name: productDetails?.custom_brand || ''
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: reviews?.rating_average || '0',
        reviewCount: reviews?.review_count || '0'
      },
      offers: {
        '@type': 'Offer',
        priceCurrency: productDetails?.price?.final_price?.currency,
        price: productDetails?.price?.final_price?.value,
        priceValidUntil: productDetails?.priceValidUntil,
        availability:
          product?.stock_status === 'IN_STOCK'
            ? 'https://schema.org/InStock'
            : 'https://schema.org/OutOfStock',
        seller: {
          '@type': 'Organization',
          name: productDetails?.custom_manufacturer
        }
      },
      review: reviews?.reviews?.items?.length
        ? reviews?.reviews?.items.map(item => ({
            '@type': 'Review',
            reviewRating: {
              '@type': 'Rating',
              ratingValue: item?.ratings_breakdown?.[0]?.value || '0' // Use the first rating value
            },
            author: {
              '@type': 'Person',
              name: item?.nickname || ''
            }
          }))
        : []
    };
  }, [productDetails, reviews, product]);

  const isOutOfStock = useMemo(() => {
    const configurableOptions =
      product?.configurable_options as ConfigurableOptions;
    const selectedFlavor = first(
      configurableOptions?.map(({ attribute_id, values }) =>
        values?.find(
          item => optionSelections?.get(attribute_id) === item.value_index
        )
      )
    );

    if (product) {
      // If has the vendor_is_in_transition flag, we should not show the out of stock message on the product page
      // But if the productPageAvailabilityError is true, it means that already tried to fetch the product availability on the fallback store
      if (isVendorInTransition && !productPageAvailabilityError) return false;

      switch (product?.__typename) {
        case 'SimpleProduct':
          return product?.stock_status === 'OUT_OF_STOCK';
        case 'ConfigurableProduct':
          return (
            product?.variants?.find(
              ({ product: variant }) =>
                variant.custom_flavor === selectedFlavor?.value_index
            ).product?.stock_status === 'OUT_OF_STOCK'
          );
        default:
          return false;
      }
    }
  }, [
    isVendorInTransition,
    optionSelections,
    product,
    productPageAvailabilityError
  ]);

  useEffect(() => {
    const scriptTag = document.querySelector(
      'script[type="application/ld+json"]'
    );
    if (scriptTag) {
      scriptTag.innerHTML = JSON.stringify(richSnippets());
    }
  }, [productDetails, reviews, quantity, richSnippets]);

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(richSnippets()) }}
      ></script>

      <ProductContext.Provider
        value={{
          product: {
            ...product,
            ...productDetails,
            media_gallery_entries: mediaGalleryEntries
          },
          isConfigurable: isProductConfigurable(product),
          configurableOptions: product?.configurable_options,
          handleSelectionChange,
          optionSelections,
          isOutOfStock,
          state: {
            quantity: {
              current: quantity,
              set: setQuantity
            },
            reviews: {
              current: reviews,
              set: setReviews
            }
          },
          isMissingOptions
        }}
      >
        {children}
      </ProductContext.Provider>
    </>
  );
};

export { ProductPageProvider, useProductPage };
