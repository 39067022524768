import { memo, useEffect, useMemo } from 'react';
import Link from 'next/link';
import { MegaMenu } from './Megamenu';
import { Autocomplete } from './Autocomplete';
import { NavigationMenuMobile } from './NavigationMenuMobile';
import LocationPinIcon from '@assets/icons/location-pin.svg';
import BurgerMenuIcon from '@assets/icons/burger-menu.svg';
import CalendarIcon from '@assets/icons/calendar-big.svg';
import TruckIcon from '@assets/icons/truck.svg';
import CartIcon from '@assets/icons/cart.svg';
import logoImg from '@assets/logo.png';
import darkLogoImg from '@assets/logo-BF.png';
import React, { useState } from 'react';
import { ZipcodeModal } from './ZipcodeModal';
import { LineBanner } from './LineBanner';
import UserMenu from './UserMenu';
import {
  useCart,
  useGTMDataLayer,
  useIsMounted,
  useRecurrence,
  useZipcode
} from '@hooks';
import { useCartTrigger } from '../../../hooks/useCartTrigger';
import { MiniCart } from '@components/common/MiniCart';
import { SignedInLink } from '../SignedInLink';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/navigation';
import { gql, useQuery } from '@apollo/client';
import { PromotionalCountdown } from '../PromotionContdown';
import { isEmpty } from 'lodash';
import { isAfter } from 'date-fns';
import { GetPromotionDocument } from '@generated/graphql';
import Image from 'next/image';

export const GET_DARK_THEME = gql`
  query getDarkTheme {
    storeConfig {
      skin_black_friday
    }
  }
`;

export const Header = memo(() => {
  const { data } = useQuery(GET_DARK_THEME);
  const [promotionalDateIsExpired, setPromotionalDateIsExpired] =
    useState(true);

  useEffect(() => {
    if (data?.storeConfig?.skin_black_friday) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [data]);

  const { pushToDataLayer } = useGTMDataLayer();
  const { replace } = useRouter();

  const isMounted = useIsMounted();
  const {
    recurrenceConfig: { enabled }
  } = useRecurrence();

  const { data: dataPromotion } = useQuery(GetPromotionDocument, {
    variables: { sku: '' },
    fetchPolicy: 'cache-and-network'
  });

  const promotionalProduct = useMemo(() => {
    const promotionalProduct = dataPromotion?.promotionBf;

    if (!isEmpty(promotionalProduct)) return promotionalProduct[0];

    return null;
  }, [dataPromotion]);

  useEffect(() => {
    if (promotionalProduct?.date_end) {
      setPromotionalDateIsExpired(
        isAfter(new Date(), new Date(promotionalProduct?.date_end))
      );
    }
  }, [promotionalProduct?.date_end]);

  const { miniCartIsOpen, setMiniCartIsOpen } = useCartTrigger();
  const { cartItemsQuantity } = useCart();
  const itemCountDisplay = useMemo(
    () => (cartItemsQuantity > 99 ? '99+' : cartItemsQuantity),
    [cartItemsQuantity]
  );

  const [navigationMenu, setNavigationMenu] = useState<boolean>(false);
  const [zipcodeIsVisible, setZipcodeIsVisible] = useState<boolean>(false);
  const { zipcode } = useZipcode();

  const handleToggleMenu = () => setNavigationMenu(state => !state);
  const handleToggleZipcodeModal = () => setZipcodeIsVisible(state => !state);

  useEffect(() => {
    if (miniCartIsOpen) {
      pushToDataLayer('popInMiniCart', {});
    }
  }, [miniCartIsOpen, pushToDataLayer]);

  return (
    <header
      id="top"
      className="top-0 z-30 flex flex-col items-center justify-center w-full font-sans text-gray-dark bg-white-lightest dark:bg-black"
    >
      {promotionalProduct && !promotionalDateIsExpired ? (
        <PromotionalCountdown />
      ) : (
        <LineBanner />
      )}
      <section className="flex items-center w-full px-5 py-3 bg-white-lightest dark:bg-black lg:justify-between lg:py-6 lg:max-w-wrapper lg:px-6">
        <button
          type="button"
          onClick={handleToggleMenu}
          className="block lg:hidden"
        >
          <BurgerMenuIcon />
        </button>

        <Link
          href="/"
          className="w-[max-content] h-[40px] lg:h-[55px] gtm-logo mx-auto lg:mx-0 absolute left-[50%] translate-x-[-50%] lg:translate-x-0 lg:left-0 lg:static"
        >
          <>
            <span className="dark:lg:hidden dark:block hidden ml-7">
              <Image
                src={darkLogoImg}
                width={150}
                height={60}
                alt="Logo Mundo Danone"
              />
            </span>
            <span className="items-center hidden h-full dark:lg:flex mt-2">
              <Image
                src={darkLogoImg}
                width={190}
                height={70}
                alt="Logo Mundo Danone"
              />
            </span>
            <span className="lg:hidden dark:hidden">
              <Image
                src={logoImg}
                width={130}
                height={40}
                alt="Logo Mundo Danone"
              />
            </span>
            <span className="items-center hidden dark:hidden h-full lg:flex">
              <Image
                src={logoImg}
                width={180}
                height={55}
                alt="Logo Mundo Danone"
              />
            </span>
          </>
        </Link>

        <Autocomplete />

        <div className="flex ml-auto">
          <div className="flex items-center justify-between lg:ml-auto">
            {enabled != '0' && (
              <SignedInLink
                href="/signature"
                className="hidden justify-between w-full lg:max-w-[max-content] items-center mx-4 lg:flex shrink-0"
              >
                <>
                  <CalendarIcon
                    height={32}
                    width={32}
                    className="self-center flex-shrink-0 mr-2 text-primary-medium"
                  />
                  <span className="text-[10px] text-left dark:text-white">
                    Programar compras <br />
                    <span className="font-[700] text-xs">
                      Minhas assinaturas
                    </span>
                  </span>
                </>
              </SignedInLink>
            )}

            <SignedInLink
              href="/orders"
              className="hidden justify-between w-full lg:max-w-[max-content] items-center mx-4 lg:flex"
            >
              <>
                <TruckIcon className="items-center self-center mr-2 -mt-1 keep-color text-primary-medium" />

                <span className="text-[10px] text-left dark:text-white">
                  Acompanhar
                  <br />
                  <span className="font-bold text-xs">Meus Pedidos</span>
                </span>
              </>
            </SignedInLink>

            <UserMenu />
          </div>

          <button
            type="button"
            onClick={() => {
              if (isMobile) {
                replace('/cart');
              } else {
                setMiniCartIsOpen(true);
              }
            }}
            className="flex items-center px-3 py-3 ml-4 rounded lg:ml-10 lg:px-6 bg-primary-lightest dark:bg-primary-darkest"
          >
            <CartIcon
              height={16}
              width={16}
              className="flex-shrink-0 text-primary-medium"
            />
            {cartItemsQuantity > 0 && (
              <span className="ml-2 font-bold empty:ml-0 lg:font-sm text-gray-dark">
                {itemCountDisplay}
              </span>
            )}
          </button>
          <MiniCart isOpen={miniCartIsOpen} setIsOpen={setMiniCartIsOpen} />
        </div>
      </section>

      <Autocomplete containerClass="flex lg:hidden" />

      <section className="w-full bg-white-light dark:bg-primary-darkest relative border-y dark:border-0 border-gray-lightest shadow-[0px_8px_16px_-4px_rgba(7,_54,_101,_0.04)]">
        <div className="flex items-center w-full mx-auto lg:max-w-wrapper">
          <button
            className="flex group items-center px-2 lg:justify-center bg-primary-medium hover:bg-primary-dark text-white py-3 w-full h-[50px] lg:w-[254px] relative"
            type="button"
            onClick={handleToggleZipcodeModal}
          >
            {isMounted && (
              <>
                <LocationPinIcon
                  height={16}
                  width={16}
                  className="text-white"
                />
                <strong className="ml-2 text-sm">
                  Enviar para {zipcode || 'o CEP?'}
                </strong>
                {!zipcode && (
                  <div className="absolute top-0 hidden z-10 w-full p-6 mt-12 text-sm font-bold text-left rounded-b-lg bg-primary-dark lg:group-hover:block">
                    A disponibilidade e preço podem variar conforme a região.
                    Informe o CEP de envio para uma melhor navegação.
                  </div>
                )}
              </>
            )}
          </button>

          <MegaMenu />
        </div>
      </section>

      {/* Conditionally built to when closed preserve the state integrity */}
      {navigationMenu && (
        <NavigationMenuMobile
          isVisible={navigationMenu}
          handleToggle={handleToggleMenu}
        />
      )}

      {zipcodeIsVisible && (
        <ZipcodeModal
          isVisible={zipcodeIsVisible}
          handleToggle={handleToggleZipcodeModal}
          onCloseModal={() => {
            setZipcodeIsVisible(false);
          }}
        />
      )}
    </header>
  );
});

Header.displayName = 'Header';
