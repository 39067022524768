import { has, merge } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';
// import useStore from '../../hooks/useStore';
// import { useUserContext } from '@magento/peregrine/lib/context/user';
// import { cardNumber } from 'card-validator/dist/card-number';
import { isSignedInAtom, userAtom } from '@contexts/auth';
import MD5 from 'crypto-js/md5';
import SHA256 from 'crypto-js/sha256';
import { useAtomValue } from 'jotai';
import { isDesktop, isMobile } from 'react-device-detect';
import { useAdminUser } from './useAdminUser';
import { useCart } from './useCart';
import useCustomerOrderCount from './useCustomerOrderCount';
import { useRecurrenceList } from './useRecurrenceList';
import { useStore } from './useStore';

// Helper function
/** USER */

const getUserType = data => {
  if (data?.is_signed_in && data?.order_count > 0) {
    return 'returning';
  }
  return 'prospect';
};

const getNewCustomer = data => {
  const userType = getUserType(data);

  if (userType === 'prospect') {
    return '1';
  }

  return '0';
};

const getHashValue = (value, hashFunction) => {
  if (value?.length) {
    return hashFunction(value).toString();
  }
  return '';
};

const getUserId = data => getHashValue(data?.user_info?.email, MD5);

const getUserEmail = data => getHashValue(data?.user_info?.email, SHA256);

/** PRODUCT */

const getProductAvailability = data =>
  data?.stock_status === 'IN_STOCK' ? '1' : '0';

const getProductList = data =>
  data?.product_list?.items?.map(product => ({
    id: product.sku
  })) ?? [];

/** LOGIN AND SIGNUP */

const getSuccessStatus = success => (success ? 'success' : 'error');

export const gtmDataLayerConfig = {
  reset: () => ({
    ecommerce: undefined,
    items: undefined,
    details: undefined
  }),
  productPage: data => {
    return {
      event: 'virtualPageview',
      pagePath: `${window.location.pathname}${window.location.search}`,
      pageTitle: `${data?.name}`,
      pageType: 'product',
      is_signed_in: String(data?.is_signed_in),
      user_logged: String(data?.is_signed_in),
      user_type: String(getUserType(data)),
      user_id: getUserId(data),
      user_email: getUserEmail(data),
      new_customer: getNewCustomer(data),
      user_recurent_order: data?.user_recurrent_order,
      env_site: 'mundodanone',
      env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
      is_subscription: String(data?.is_subscription),
      is_admin: String(data?.is_admin),
      store: String(data?.store),
      page_type: 'product',
      page_name: `${data?.name}`,
      page_url: `${window.location.pathname}${window.location.search}`,
      env_work: process.env.NEXT_PUBLIC_ENVINROMENT,

      product_name: String(data?.variantName || data?.name),
      product_id: String(data?.sku),
      product_parent_sku: String(data?.parent_sku),
      product_is_configurable: String(data?.is_configurable),
      product_price: String(
        data?.price_range?.minimum_price?.final_price?.value
      ),
      product_category: String(data?.category),
      product_brand: String(data?.custom_brand),
      product_format: String(data?.custom_format),
      product_packing: String(data?.custom_packing),
      product_manufacturer: String(data?.custom_manufacturer),
      product_weight: String(data?.custom_weight),
      product_flavor: String(data?.custom_flavor),
      product_stock_status: String(data?.stock_status),
      product_availability: String(getProductAvailability(data)),
      product_position: '1',
      items: [
        {
          id: String(data?.sku)
        }
      ],
      ecommerce: {
        store: String(data?.store),
        detail: {
          items: [
            {
              item_name: String(data?.variantName || data?.name),
              item_id: String(data?.sku),
              parent_sku: String(data?.parent_sku),
              is_configurable: String(data?.is_configurable),
              price: String(
                data?.price_range?.minimum_price?.final_price?.value
              ),
              item_category: String(data?.category),
              item_brand: String(data?.custom_brand),
              format: String(data?.custom_format),
              packing: String(data?.custom_packing),
              manufacturer: String(data?.custom_manufacturer),
              weight: String(data?.custom_weight),
              flavor: String(data?.custom_flavor),
              stock_status: String(data?.stock_status),
              availability: String(getProductAvailability(data)),
              position: '1'
            }
          ]
        }
      }
    };
  },
  productContent: data => ({
    event: 'view_item',
    currency: 'BRL',
    value: String(data?.price?.final_price?.value),
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    user_recurent_order: data?.user_recurrent_order,
    is_subscription: String(data?.is_subscription),
    is_admin: String(data?.is_admin),
    store: String(data?.store),

    ecommerce: {
      items: [
        {
          item_id: String(data?.sku),
          item_name: String(data?.variantName || data?.name),
          price: String(data?.price_range?.minimum_price?.final_price?.value),
          // Fields below (undefined) are not present currently, but they will be added in the future
          coupon: undefined,
          discount: undefined,
          quantity: 1,
          parent_sku: String(data?.parent_sku),
          is_configurable: String(data?.is_configurable),
          item_category: String(data?.category),
          item_brand: String(data?.custom_brand),
          format: String(data?.custom_format),
          packing: String(data?.custom_packing),
          manufacturer: String(data?.custom_manufacturer),
          weight: String(data?.custom_weight),
          flavor: String(data?.custom_flavor),
          stock_status: String(data?.stock_status),
          availability: String(getProductAvailability(data))
        }
      ]
    }
  }),
  showcaseProducts: data => ({
    event: 'view_item_list',
    ecommerce: {
      currencyCode: 'BRL',
      items: data?.products?.map((item, index) => {
        return {
          item_name: String(item?.name),
          item_id: String(item?.sku),
          price: String(item?.price_range?.minimum_price?.final_price?.value),
          item_brand: String(item?.custom_brand ?? ''),
          item_category: String(data?.list),
          item_list_name: String(data?.list),
          position: index + 1
        };
      })
    }
  }),
  selectItem: data => ({
    event: 'select_item',
    ecommerce: {
      currencyCode: 'BRL',
      items: [
        {
          item_name: String(data?.name),
          item_id: String(data?.sku),
          price: String(data?.price_range?.minimum_price?.final_price?.value),
          item_brand: String(data?.custom_brand ?? ''),
          item_category: String(data?.list),
          item_list_name: String(data?.list),
          position: data?.position
        }
      ]
    }
  }),
  cms: data => ({
    event: 'virtualPageview',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `${data?.title}`,
    pageType: 'cms',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: String(data?.page_type),
    page_name: `${data?.title}`,
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT
  }),
  signInPage: data => ({
    event: 'virtualPageview',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `Sign In`,
    pageType: 'signIn',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'signin',
    page_name: 'Sign In',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT
  }),
  searchPage: data => ({
    event: 'virtualPageview',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `Search`,
    pageType: 'search',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    product_list: getProductList(data),
    page_number: String(data?.page_number),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'search',
    page_name: 'Search',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT,
    results_number: String(data?.results_number)
  }),
  forgotPasswordPage: data => ({
    event: 'virtualPageview',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `Forgot Password`,
    pageType: 'forgotPassword',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'forgotPassword',
    page_name: 'Forgot Password',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT
  }),
  createAccountPage: data => ({
    event: 'virtualPageview',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `Create Account`,
    pageType: 'createAccount',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'createAccount',
    page_name: 'Create Account',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT
  }),
  orderHistoryPage: data => ({
    event: 'virtualPageview',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `Order History`,
    pageType: 'orderHistory',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'orderHistory',
    page_name: 'Order History',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT
  }),
  subscriptionPage: data => ({
    event: 'virtualPageview',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `My subscriptions`,
    pageType: 'subscription',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'subscription',
    page_name: 'My subscriptions',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT
  }),
  accountPage: data => ({
    event: 'virtualPageview',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `My account`,
    pageType: 'account',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'account',
    page_name: 'My account',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT
  }),
  addressBookPage: data => ({
    event: 'virtualPageview',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `Address book`,
    pageType: 'addressBook',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'addressBook',
    page_name: 'Address book',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT
  }),
  orderConfirmationPage: data => ({
    event: 'virtualPageview',
    pagePath: `${window.location.pathname}${window.location.search}#success`,
    pageTitle: `Success page`,
    pageType: 'successPage',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'successPage',
    page_name: 'Success page',
    page_url: `${window.location.pathname}${window.location.search}#success`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT
  }),
  notFoundPage: data => ({
    event: 'virtualPageview',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `Not found`,
    pageType: 'error',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'error',
    page_name: 'Not Found',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT
  }),
  category: data => ({
    event: 'virtualPageview',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `${data?.category?.name}`,
    pageType: 'category',
    category_id: String(data?.category?.id),
    product_list: getProductList(data),
    page_number: String(data?.page_number),
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'category',
    page_name: `${data?.category?.name}`,
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT
  }),
  cartPage: data => ({
    event: 'view_cart',
    event_name: 'view_cart',
    currency: 'BRL',
    value: String(data?.prices?.subtotal_excluding_tax?.value),
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    env_channel: 'desktop',
    user_recurent_order: data?.user_recurrent_order,
    is_subscription: String(data?.is_subscription),
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    ecommerce: {
      items: data?.items?.map(item => {
        return (
          item.product && {
            item_id: String(item.product.sku),
            item_name: String(item.product.name),
            price:
              item?.product?.price_range &&
              String(
                item?.product?.price_range?.minimum_price?.final_price.value
              ),
            coupon: String(data.coupon || ''),
            discount: String(data.discount || 0),
            quantity: String(item.quantity),
            parent_sku: String(item?.parent_sku),
            is_configurable: String(item?.is_configurable),
            item_category:
              item.product.category && String(item.product.category?.name),
            item_brand: String(item.product.custom_brand),
            format: String(item.product.custom_format),
            packing: String(item.product.custom_packing),
            manufacturer: String(item.product.custom_manufacturer),
            weight: String(item.product.custom_weight),
            flavor: String(item.product.custom_flavor),
            stock_status: String(data?.stock_status),
            availability: String(getProductAvailability(data))
          }
        );
      })
    }
  }),
  removeFromCart: data => ({
    event: 'remove_from_cart',
    currency: 'BRL',
    value: Number.parseFloat(data?.value ? data?.value : 0).toFixed(2),
    page_url: `${window.location.pathname}${window.location.search}`,
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    env_channel: 'desktop',
    user_recurent_order: data?.user_recurrent_order,
    is_subscription: String(data?.is_subscription),
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    ecommerce: {
      items: [
        {
          item_id: String(data?.sku),
          item_name: String(data?.variantName || data?.name),
          price: String(data?.price?.final_price?.value || 0),
          coupon: String(data.coupon || ''),
          discount: String(data.discount || 0),
          quantity: String(data?.quantity),
          parent_sku: String(data?.parent_sku),
          is_configurable: String(data?.is_configurable),
          item_category: String(
            data?.categories?.length > 0
              ? data?.categories?.[0]?.name
              : data?.category?.name
          ),
          item_brand: String(data?.custom_brand),
          format: String(data?.custom_format),
          packing: String(data?.custom_packing),
          manufacturer: String(data?.custom_manufacturer),
          weight: String(data?.custom_weight),
          flavor: String(data?.custom_flavor),
          stock_status: String(data?.stock_status),
          availability: String(getProductAvailability(data))
        }
      ]
    }
  }),
  updateItemQuantityOnCart: data => ({
    event: 'updateItemQuantityOnCart',
    ecommerce: {
      currency: 'BRL',
      store: String(data?.store),
      is_signed_in: String(data?.is_signed_in),
      user_logged: String(data?.is_signed_in),
      user_type: String(getUserType(data)),
      user_id: getUserId(data),
      user_email: getUserEmail(data),
      new_customer: getNewCustomer(data),
      user_recurent_order: data?.user_recurrent_order,
      env_site: 'mundodanone',
      is_subscription: String(data?.is_subscription),
      is_admin: String(data?.is_admin),
      remove: {
        items: [
          {
            item_name: String(data?.name),
            item_id: String(data?.sku),
            quantity: String(data?.quantity)
          }
        ]
      }
    }
  }),
  addToCart: data => ({
    event: 'add_to_cart',
    currency: 'BRL',
    value: Number.parseFloat(data?.value ? data?.value : 0).toFixed(2),
    page_url: `${window.location.pathname}${window.location.search}`,
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    env_channel: 'desktop',
    user_recurent_order: data?.user_recurrent_order,
    is_subscription: String(data?.is_subscription),
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    ecommerce: {
      items: [
        {
          item_id: String(data?.sku),
          item_name: String(data?.variantName || data?.name),
          price: String(data?.price?.final_price?.value || 0),
          coupon: String(data.coupon || ''),
          discount: String(data.discount || 0),
          quantity: String(data?.quantity),
          parent_sku: String(data?.parent_sku),
          is_configurable: String(data?.is_configurable),
          item_category: String(
            data?.categories?.length > 0
              ? data?.categories?.[0]?.name
              : data?.category?.name
          ),
          item_brand: String(data?.custom_brand),
          format: String(data?.custom_format),
          packing: String(data?.custom_packing),
          manufacturer: String(data?.custom_manufacturer),
          weight: String(data?.custom_weight),
          flavor: String(data?.custom_flavor),
          stock_status: String(data?.stock_status),
          availability: String(getProductAvailability(data))
        }
      ]
    }
  }),
  checkoutPage: data => ({
    event: 'begin_checkout',
    currency: 'BRL',
    value: String(data?.prices?.subtotal_excluding_tax?.value),
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    user_recurent_order: data?.is_recurrence,
    is_subscription: String(data?.is_recurrence),
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    ecommerce: {
      items: data?.items?.map(item => {
        return {
          item_id: String(item.product.sku),
          item_name: String(item.product.name),
          price: String(
            item?.product?.price_range?.minimum_price?.final_price.value
          ),
          coupon: String(data.coupon || ''),
          discount: String(data.prices?.discounts?.[0]?.amount?.value || 0),
          quantity: String(item.quantity),
          parent_sku: String(item?.parent_sku),
          is_configurable: String(item?.is_configurable),
          item_category: String(item?.product?.categories[0]?.name ?? ''),
          item_brand: String(item.product.custom_brand),
          format: String(item.product.custom_format),
          packing: String(item.product.custom_packing),
          manufacturer: String(item.product.custom_manufacturer),
          weight: String(item.product.custom_weight),
          flavor: String(item.product.custom_flavor),
          stock_status: String(item?.product?.stock_status),
          availability: String(getProductAvailability(data))
        };
      })
    }
  }),
  checkoutPageView: data => ({
    event: 'virtualPageView',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `Checkout Page`,
    pageType: 'checkoutPage',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    user_recurent_order: data?.user_recurrent_order,
    is_subscription: String(data?.is_subscription),
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'checkoutPage',
    page_name: 'Checkout Page',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT,
    ecommerce: {
      checkout: {
        currency: 'BRL',
        actionField: { step: 1, option: 'Checkout iniciado' },
        total_quantity: String(data?.total_quantity),
        total: String(data?.prices?.grand_total.value),
        value: String(data?.prices?.subtotal_excluding_tax?.value),
        applied_coupons: data?.applied_coupons,
        items: data?.items?.map(item => {
          return {
            item_id: String(item.product.sku),
            item_name: String(item.product.name),
            quantity: String(item.quantity),
            price: String(
              item?.product?.price_range?.minimum_price?.final_price.value
            ),
            item_category: String(item?.product?.category?.name ?? ''),
            item_brand: String(item.product.custom_brand),
            format: String(item.product.custom_format),
            packing: String(item.product.custom_packing),
            manufacturer: String(item.product.custom_manufacturer),
            weight: String(item.product.custom_weight),
            flavor: String(item.product.custom_flavor)
          };
        })
      }
    }
  }),
  checkoutPageAddress: data => ({
    event: 'checkout',
    pagePath: `${window.location.pathname}#address`,
    pageTitle: `Checkout Page`,
    pageType: 'checkoutPage',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    user_recurent_order: data?.user_recurrent_order,
    is_subscription: String(data?.is_subscription),
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'checkoutPage',
    page_name: 'Checkout Page',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT,
    ecommerce: {
      checkout: {
        currency: 'BRL',
        actionField: { step: 1, option: 'Endereço iniciado' }
      }
    }
  }),
  checkoutPageAddressPageView: data => ({
    event: 'virtualPageView',
    pagePath: `${window.location.pathname}#address`,
    pageTitle: `Checkout Page`,
    pageType: 'checkoutPage',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    user_recurent_order: data?.user_recurrent_order,
    is_subscription: String(data?.is_subscription),
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'checkoutPage',
    page_name: 'Checkout Page',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT,
    ecommerce: {
      checkout: {
        currency: 'BRL',
        actionField: { step: 2, option: 'Endereço iniciado' },
        total_quantity: String(data?.total_quantity),
        total: String(data?.prices?.grand_total.value),
        value: String(data?.prices?.subtotal_excluding_tax?.value),
        applied_coupons: data?.applied_coupons,
        items: data?.items?.map(item => {
          return {
            item_id: String(item.product.sku),
            item_name: String(item.product.name),
            quantity: String(item.quantity),
            price: String(
              item?.product?.price_range?.minimum_price?.final_price.value
            ),
            item_category: String(item?.product?.category?.name ?? ''),
            item_brand: String(item.product.custom_brand),
            format: String(item.product.custom_format),
            packing: String(item.product.custom_packing),
            manufacturer: String(item.product.custom_manufacturer),
            weight: String(item.product.custom_weight),
            flavor: String(item.product.custom_flavor)
          };
        })
      }
    }
  }),
  checkoutPageShipping: data => ({
    event: 'add_shipping_info',
    currency: data?.prices?.grand_total?.currency,
    value: String(data?.prices?.grand_total?.value) || '0',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    user_recurent_order: data?.user_recurrent_order,
    is_subscription: String(data?.is_subscription),
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    ecommerce: {
      items: data?.items?.map(item => {
        return {
          item_id: String(item.product?.sku),
          item_name: item.product?.name,
          price: String(
            item.product?.price_range?.minimum_price?.final_price?.value
          ),
          coupon: data?.applied_coupons?.[0]?.code || '',
          discount: String(data?.prices?.discounts?.[0]?.amount?.value) || '',
          quantity: String(item.quantity),
          parent_sku: String(item?.product?.parent_sku),
          is_configurable: String(item?.product?.is_configurable),
          item_category: item.product?.category?.name,
          item_brand: item.product?.custom_brand,
          format: item.product?.custom_format,
          packing: item.product?.custom_packing,
          manufacturer: item.product?.custom_manufacturer,
          weight: String(item.product?.custom_weight),
          flavor: item.product?.custom_flavor,
          stock_status: item.product?.stock_status,
          availability: item?.product?.stock_status === 'IN_STOCK' ? '1' : '0'
        };
      })
    }
  }),
  checkoutPageShippingPageView: data => ({
    event: 'virtualPageView',
    pagePath: `${window.location.pathname}#shipping`,
    pageTitle: `Checkout Page`,
    pageType: 'checkoutPage',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    user_recurent_order: data?.user_recurrent_order,
    is_subscription: String(data?.is_subscription),
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'checkoutPage',
    page_name: 'Checkout Page',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT,
    ecommerce: {
      checkout: {
        currency: 'BRL',
        actionField: { step: 3, option: 'Frete iniciado' },
        total_quantity: String(data?.total_quantity),
        total: String(data?.prices?.grand_total.value),
        value: String(data?.prices?.subtotal_excluding_tax?.value),
        applied_coupons: data?.applied_coupons,
        items: data?.items?.map(item => {
          return {
            item_id: String(item.product.sku),
            item_name: String(item.product.name),
            quantity: String(item.quantity),
            price: String(
              item?.product?.price_range?.minimum_price?.final_price.value
            ),
            item_category: String(item?.product?.category?.name ?? ''),
            item_brand: String(item.product.custom_brand),
            format: String(item.product.custom_format),
            packing: String(item.product.custom_packing),
            manufacturer: String(item.product.custom_manufacturer),
            weight: String(item.product.custom_weight),
            flavor: String(item.product.custom_flavor)
          };
        })
      }
    }
  }),
  checkoutPagePayment: data => ({
    event: 'add_payment_info',
    currency: data?.prices?.grand_total?.currency,
    value: String(data?.prices?.grand_total?.value) || '0',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    user_recurent_order: data?.user_recurrent_order,
    is_subscription: String(data?.is_subscription),
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    ecommerce: {
      items: data?.items?.map(item => {
        return {
          item_id: String(item.product?.sku),
          item_name: item.product?.name,
          price: String(
            item.product?.price_range?.minimum_price?.final_price?.value
          ),
          coupon: data?.applied_coupons?.[0]?.code || '',
          discount: String(data?.prices?.discounts?.[0]?.amount?.value) || '',
          quantity: String(item.quantity),
          parent_sku: String(item?.product?.parent_sku),
          is_configurable: String(item?.product?.is_configurable),
          item_category: item.product?.category?.name,
          item_brand: item.product?.custom_brand,
          format: item.product?.custom_format,
          packing: item.product?.custom_packing,
          manufacturer: item.product?.custom_manufacturer,
          weight: String(item.product?.custom_weight),
          flavor: item.product?.custom_flavor,
          stock_status: item.product?.stock_status,
          availability: item?.product?.stock_status === 'IN_STOCK' ? '1' : '0'
        };
      })
    }
  }),
  checkoutPagePaymentPageView: data => ({
    event: 'virtualPageView',
    pagePath: `${window.location.pathname}#payment`,
    pageTitle: `Checkout Page`,
    pageType: 'checkoutPage',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    user_recurent_order: data?.user_recurrent_order,
    is_subscription: String(data?.is_subscription),
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    page_type: 'checkoutPage',
    page_name: 'Checkout Page',
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT,
    ecommerce: {
      checkout: {
        currency: 'BRL',
        actionField: { step: 4, option: 'Pagamento iniciado' },
        total_quantity: String(data?.total_quantity),
        total: String(data?.prices?.grand_total.value),
        value: String(data?.prices?.subtotal_excluding_tax?.value),
        applied_coupons: data?.applied_coupons,
        items: data?.items?.map(item => {
          return {
            item_id: String(item.product.sku),
            item_name: String(item.product.name),
            quantity: String(item.quantity),
            price: String(
              item?.product?.price_range?.minimum_price?.final_price.value
            ),
            item_category: String(item?.product?.category?.name ?? ''),
            item_brand: String(item.product.custom_brand),
            format: String(item.product.custom_format),
            packing: String(item.product.custom_packing),
            manufacturer: String(item.product.custom_manufacturer),
            weight: String(item.product.custom_weight),
            flavor: String(item.product.custom_flavor)
          };
        })
      }
    }
  }),
  checkoutOption: data => ({
    event: 'checkoutOption',
    pageType: data?.pageType,
    ecommerce: {
      currency: 'BRL',
      checkout_option: {
        actionField: { step: data?.step, option: data?.option },
        total_quantity: String(data?.total_quantity),
        total: String(data?.prices?.grand_total.value),
        value: String(data?.prices?.subtotal_excluding_tax?.value),
        applied_coupons: data?.applied_coupons,
        items: data?.items?.map(item => {
          return {
            item_id: String(item.product.sku),
            item_name: String(item.product.name),
            quantity: String(item.quantity),
            price: String(
              item?.product?.price_range?.minimum_price?.final_price.value
            ),
            item_category: String(item?.product?.category?.name ?? ''),
            item_brand: String(item.product.custom_brand),
            format: String(item.product.custom_format),
            packing: String(item.product.custom_packing),
            manufacturer: String(item.product.custom_manufacturer),
            weight: String(item.product.custom_weight),
            flavor: String(item.product.custom_flavor)
          };
        })
      }
    },
    additional_info: data?.additional_info || null
  }),
  purchase: data => ({
    event: 'purchase',
    transaction_id: String(data?.number),
    shipping: String(
      data?.cart?.shipping_addresses?.[0]?.selected_shipping_method?.amount
        ?.value
    ),
    currency: data?.cart?.prices?.grand_total?.currency,
    value: String(data?.cart?.prices?.grand_total?.value) || '0',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    env_site: 'mundodanone',
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    user_recurent_order: data?.user_recurrent_order,
    is_subscription: String(data?.is_subscription),
    is_admin: String(data?.is_admin),
    store: String(data?.store),
    ecommerce: {
      items: data?.cart?.items?.map(item => {
        return {
          item_id: String(item.product?.sku),
          item_name: item.product?.name,
          price: String(
            item.product?.price_range?.minimum_price?.final_price?.value
          ),
          coupon: data?.cart?.applied_coupons?.[0]?.code || '',
          discount:
            String(data?.cart?.prices?.discounts?.[0]?.amount?.value) || '',
          quantity: String(item.quantity),
          parent_sku: String(item?.product?.parent_sku),
          is_configurable: String(item?.product?.is_configurable),
          item_category: item.product?.category?.name,
          item_brand: item.product?.custom_brand,
          format: item.product?.custom_format,
          packing: item.product?.custom_packing,
          manufacturer: item.product?.custom_manufacturer,
          weight: String(item.product?.custom_weight),
          flavor: item.product?.custom_flavor,
          stock_status: item.product?.stock_status,
          availability: item?.product?.stock_status === 'IN_STOCK' ? '1' : '0'
        };
      })
    }
  }),
  whatsappTrigger: data => ({
    event: 'whatsapp',
    eventCategory: 'whatsapp',
    eventAction: 'clique',
    eventLabel: 'chat',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store)
  }),
  phoneTrigger: data => ({
    event: 'telefone',
    eventCategory: 'telefone',
    eventAction: 'clique',
    eventLabel: 'call',
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    user_recurent_order: data?.user_recurrent_order,
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    store: String(data?.store)
  }),
  popInMiniCart: () => ({
    event: 'virtualPageView',
    page_url: '/virtual-cart',
    page_type: 'virtual-cart'
  }),
  productReview: data => ({
    event: 'interaction',
    event_name: 'add_review',
    product_name: data?.name
  }),
  manageSubscription: data => ({
    event: 'interaction',
    event_name: 'manage_subscription',
    order_frequency: data?.order_frequency
  }),
  cancelSubscription: data => ({
    event: 'interaction',
    event_name: 'subscription_cancelled',
    product_name: data?.product_name,
    order_frequency: data?.order_frequency
  }),
  completeSubscription: data => ({
    event: 'interaction',
    event_name: 'subscription_completed',
    product_name: data?.product_name,
    order_frequency: data?.order_frequency
  }),
  optInSubscription: data => ({
    event: 'interaction',
    event_name: 'subscription_selected',
    product_name: data?.product_name,
    order_frequency: data?.order_frequency
  }),
  signup: data => ({
    event: 'interaction',
    event_name: 'registration_signup',
    method: String(data?.method),
    status: getSuccessStatus(data?.status),
    marketng_optin: data?.marketng_optin
  }),
  login: data => ({
    event: 'interaction',
    event_name: 'login',
    method: String(data?.method),
    status: getSuccessStatus(data?.status)
  }),
  logout: data => ({
    event: 'interaction',
    event_name: 'logout_dtc',
    method: String(data?.method),
    status: getSuccessStatus(data?.status)
  }),
  newsletter: data => ({
    event: 'interaction',
    event_name: 'newsletter_signup',
    status: getSuccessStatus(data?.status)
  }),
  search: data => ({
    event: 'interaction',
    event_name: 'search',
    method: 'search_bar',
    search_term: data?.search_term
  }),
  flashSaleBanner: data => ({
    event: 'select_promotion',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `Home`,
    pageType: 'homepage',
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    is_subscription: String(data?.is_subscription),
    store: String(data?.store),
    page_type: 'homepage',
    page_name: 'Home',
    user_recurent_order: data?.user_recurrent_order,
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT,
    ecommerce: {
      currencyCode: 'BRL',
      items: data?.ecommerce?.items
    }
  }),
  flashSaleBannerPageView: data => ({
    event: 'view_promotion',
    pagePath: `${window.location.pathname}${window.location.search}`,
    pageTitle: `Home`,
    pageType: 'homepage',
    env_site: 'mundodanone',
    is_admin: String(data?.is_admin),
    is_subscription: String(data?.is_subscription),
    store: String(data?.store),
    page_type: 'homepage',
    page_name: 'Home',
    user_recurent_order: data?.user_recurrent_order,
    is_signed_in: String(data?.is_signed_in),
    user_logged: String(data?.is_signed_in),
    user_type: String(getUserType(data)),
    user_id: getUserId(data),
    user_email: getUserEmail(data),
    new_customer: getNewCustomer(data),
    page_url: `${window.location.pathname}${window.location.search}`,
    env_channel: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
    env_work: process.env.NEXT_PUBLIC_ENVINROMENT,
    ecommerce: {
      currencyCode: 'BRL',
      items: data?.ecommerce?.items
    }
  }),
  subscribeBackInStock: data => ({
    event: 'interaction',
    event_name: 'back_in_stock',
    product_name: data?.product_name,
    product_id: data?.product_id,
    store: data?.store,
    date: new Date().toISOString()
  })
};

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

export const GTMDataLayer = createContext<typeof gtmDataLayerConfig | null>(
  null
);

const defaultDataLayer = gtmDataLayerConfig;

export const GTMDataLayerProvider = ({
  children,
  additionalDataLayer = {}
}) => (
  <GTMDataLayer.Provider
    value={merge({}, defaultDataLayer, additionalDataLayer)}
  >
    {children}
  </GTMDataLayer.Provider>
);

type Events = typeof gtmDataLayerConfig;

type DataLayerCallback = <T extends keyof Events>(
  eventName: keyof Events,
  eventData: Partial<ReturnType<Events[T]>> | Record<string & {}, any>,
  resetBeforePush?: boolean
) => void;

export const useGTMDataLayer = ({ runOnce = false } = {}) => {
  const events = useContext(GTMDataLayer);

  if (events === null) {
    throw new Error(
      'useGTMDataLayer must be used within a GTMDataLayerProvider'
    );
  }

  const { isSignature } = useCart();
  const user = useAtomValue(userAtom);
  const isSignedIn = useAtomValue(isSignedInAtom);
  const orderCount = useCustomerOrderCount({ isSignedIn });
  const { isAdmin } = useAdminUser();
  const { data: recurrenceData } = useRecurrenceList({ isSignedIn });

  const hasActiveSubscription = useMemo(() => {
    if (recurrenceData?.listRecurrence?.length) {
      return recurrenceData.listRecurrence.some(
        subscription => subscription.status === '1'
      );
    }
    return false;
  }, [recurrenceData]);

  const { currentStore } = useStore();

  // Using Map for allowing multiple unique events push
  const runOnceControl = useRef(new Map());

  const pushToDataLayer = useCallback<DataLayerCallback>(
    (eventName, eventData, resetBeforePush = true) => {
      if (!window || !window.dataLayer) {
        // eslint-disable-next-line no-console
        console.error('No dataLayer available');
      } else if (!has(events, eventName)) {
        // eslint-disable-next-line no-console
        console.error(`No ${eventName} event available.`);
      } else {
        if (
          currentStore &&
          (!runOnce || !runOnceControl.current.has(eventName))
        ) {
          if (resetBeforePush) {
            window.dataLayer.push(events.reset());
          }

          const data = {
            ...eventData,
            store: currentStore.code,
            user_info: user,
            order_count: orderCount,
            is_signed_in: isSignedIn,
            is_admin: isAdmin,
            is_subscription: (eventData as any)?.is_subscription ?? isSignature,
            user_recurrent_order: hasActiveSubscription ? 'yes' : 'no'
          };

          const event = events[eventName](data);

          window.dataLayer.push(event);
          if (runOnce) runOnceControl.current.set(eventName, undefined);
        }
      }
    },
    [
      events,
      currentStore,
      runOnce,
      user,
      orderCount,
      isSignedIn,
      isAdmin,
      isSignature,
      hasActiveSubscription
    ]
  );

  return { ...events, pushToDataLayer };
};

const usePushDataLayer = (
  eventName,
  eventData,
  resetBeforePush = true,
  runOnce
) => {
  const { pushToDataLayer } = useGTMDataLayer({ runOnce });

  useEffect(() => {
    pushToDataLayer(eventName, eventData, resetBeforePush);
  }, [eventData, eventName, pushToDataLayer, resetBeforePush]);
};

export const usePushToDataLayerOnce: DataLayerCallback = (
  eventName,
  eventData,
  resetBeforePush = true
) => {
  usePushDataLayer(eventName, eventData, resetBeforePush, true);
};

export const usePushToDataLayer: DataLayerCallback = (
  eventName,
  eventData,
  resetBeforePush = true
) => {
  usePushDataLayer(eventName, eventData, resetBeforePush, false);
};
